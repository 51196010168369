import { ref, watch, computed } from 'vue';
import type {
  EntityProps,
  DataProps
} from '@/views/Payment/Controlling/Dealer/DealerPaymentOverview/DealerRoundSummary/type';
import * as API from '@/API/payment';
import { downloadFromStream } from '@/utils/payment/downloadFile';
import { message } from 'ant-design-vue';
import { useStore } from 'vuex';

const originColumns = [
  {
    title: '',
    dataIndex: 'color',
    width: 20,
    align: 'center',
    slots: { customRender: 'status' }
  },
  {
    title: 'Business Unit',
    dataIndex: 'businessUnit',
    slots: { customRender: 'bold' },
    width: 150
  },
  {
    title: 'Period Total w/o VAT',
    dataIndex: 'periodwoVat',
    align: 'right',
    slots: { customRender: 'formatThousand' }
  }
];

interface Version {
  versionName: string;
}

const useDealerPayout = () => {
  const payoutRound = ref<string>();
  const pageData = ref<EntityProps[]>([]);

  const generateSelectedEntity = ref('MBCL');
  const downloadSelectedEntity = ref('MBCL');
  const versions = ref<string[]>([]);
  const downloadSelectedVersion = ref<any>([]);

  const isInRound = ref(false);
  const isApproved = ref(false);
  const getData = (payoutRound: string) => {
    API.getDealer2RoundDetailForPayout(payoutRound).then((res: any) => {
      const dynamicColumns = res.vatheader.map((item: string) => {
        return {
          title: item,
          dataIndex: item,
          align: 'right',
          slots: { customRender: 'formatThousand' }
        };
      });
      res.content.forEach((entity: EntityProps) => {
        entity.columns = originColumns.concat(dynamicColumns);
        entity.normalPayment?.forEach((item: DataProps, index: number) => {
          item.id = index;
        });
        entity.rePayment?.forEach((item: DataProps, index: number) => {
          item.id = index;
        });
      });
      pageData.value = res.content;
      isInRound.value = pageData.value.some((item: any) => item.entity === 'MBCL');
      const entityData = pageData.value.find((item) => item.entity === generateSelectedEntity.value);
      if (entityData) {
        let buData = (entityData.normalPayment ?? [])
          .filter((item) => item.color === 'green')
          .map((item) => item.businessUnit)
          .concat(
            (entityData.rePayment ?? []).filter((item) => item.color === 'green').map((item) => item.businessUnit)
          );
        buData = [...new Set(buData)];
        isApproved.value = buData.length > 0;
      }
    });
  };

  const getVersions = () => {
    if (payoutRound.value && downloadSelectedEntity.value) {
      API.getSummaryLogList(payoutRound.value, downloadSelectedEntity.value).then((res: any) => {
        versions.value = res.map((item: Version) => item.versionName);
        downloadSelectedVersion.value = versions.value.length > 0 ? versions.value : undefined;
      });
    }
  };

  watch(
    payoutRound,
    (payoutRound) => {
      if (payoutRound) {
        getData(payoutRound);
        getVersions();
      } else {
        pageData.value = [];
        versions.value = [];
      }
    },
    {
      immediate: true
    }
  );

  watch(downloadSelectedEntity, (entity) => {
    if (entity) {
      getVersions();
    } else {
      versions.value = [];
    }
  });

  const generatePayoutSummary = () => {
    const summaryLogs = [];
    const entityData = pageData.value.find((item) => item.entity === generateSelectedEntity.value);
    if (entityData) {
      let buData = (entityData.normalPayment ?? [])
        .filter((item) => item.color === 'green')
        .map((item) => item.businessUnit)
        .concat((entityData.rePayment ?? []).filter((item) => item.color === 'green').map((item) => item.businessUnit));
      buData = [...new Set(buData)];
      if (buData.length > 0) {
        for (const bu of buData) {
          summaryLogs.push({
            payoutRound: payoutRound.value,
            entity: generateSelectedEntity.value,
            bu: bu
          });
        }
        API.addSummaryLog(summaryLogs).then(() => {
          message.success('Confirm successfully.');
          getVersions();
        });
      }
    }
  };

  const downloadPayoutSummary = () => {
    console.log(downloadSelectedVersion.value);
    if (payoutRound.value && downloadSelectedVersion.value) {
      API.downloadPayOutSummaryByVersion(
        payoutRound.value,
        downloadSelectedVersion.value,
        generateSelectedEntity.value
      ).then((res: any) => {
        downloadFromStream(res);
      });
    }
  };
  // 判断当前entity里有没有 MBCL
  const { state } = useStore();
  const isHasMBCL = computed(() => {
    return state.user.entityList.map((item: any) => item.entityName).includes('MBCL');
  });

  const handleDownloadPaymentOverview = async () => {
    if (!payoutRound.value) {
      return;
    }
    const res = await API.downloadPaymentOverview(payoutRound.value);
    if (res.data.type === 'application/json') {
      const text = JSON.parse(await res.data.text());
      if (text.code !== '0') {
        message.error(text.message);
      }
    } else {
      downloadFromStream(res);
    }
  };
  return {
    payoutRound,
    versions,
    generateSelectedEntity,
    downloadSelectedEntity,
    downloadSelectedVersion,
    pageData,
    generatePayoutSummary,
    downloadPayoutSummary,
    isHasMBCL,
    isInRound,
    isApproved,
    handleDownloadPaymentOverview
  };
};
export default useDealerPayout;


import { defineComponent, ref } from "vue"
import type { PropType } from "vue"
import { RightOutlined } from "@ant-design/icons-vue"
import { toThousandsAndTwoDecimal } from '@/utils/payment/numberFormat'
import type { DataProps } from "./type"
export default defineComponent({
  name: "entity",
  components: {
    RightOutlined,
  },
  props: {
    data: {
      type: Array as PropType<DataProps[]>,
      required: true,
      default: () => []
    },
  },
  setup() {
    const getRowClass = (data: DataProps[]) => {
      return (record: DataProps, index: number) => {
        if (index + 1 === (data ?? []).length) {
          return "table-total";
        } else {
          if (index % 2 === 1) {
            return "table-striped";
          } else {
            return null;
          }
        }
      };
    };
    const activeKey = ref(["0"]);
    return {
      getRowClass,
      activeKey,
      toThousandsAndTwoDecimal
    };
  }
});
